

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import App from "App";
import Notification from "./examples/Notification";
import store from './redux/store';

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <Notification />
    </Provider>
  </BrowserRouter>
);
