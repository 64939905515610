import axios from 'axios';
import { encrypt, decrypt } from 'utils/encrypt';
import { API_URL } from 'utils/environment';

const axiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = (window.localStorage.getItem('token'));
        if (token) {
            config.headers.Authorization = `${token}`;
        }

        if (config.data) {
            config.data = JSON.parse(await encrypt(config.data));
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    async (response) => {
        if (response.data) {
            response = await decrypt(response.data);
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default axiosInstance;
