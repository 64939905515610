
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// import AuthService from "services/auth-service";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Image
import bgImage from "assets/images/background.png";

import { login } from 'redux/auth/actions';
import { selectAuth } from 'redux/auth/selectors';
import { SUPPORT_CONTACT_URL } from "utils/environment";

const Login = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(selectAuth);
  const { darkMode } = useSelector(state => state.materialUI);
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  //   const authContext = useContext(AuthContext);
  const [rememberMe, setRememberMe] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
    credentialsErros: false,
    textError: "",
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }
    const newUser = { email: inputs.email, password: inputs.password };
    dispatch(login({ loginData: newUser }));
  };


  useEffect(() => {
    document.title = "Thunder Player | Login";
  }, []);

  const handleGoToSupport = () => {
    navigate('support@thunder-player.com');
  };
  return (

    <PageLayout background="white">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) =>
            darkMode ? background.default : white.main,
        }}
      >
        <Grid item xs={12} lg={6}>
          <MDBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }}
          />
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
            <MDBox py={3} px={3} textAlign="center">
              <MDBox mb={1} textAlign="center">
                <MDTypography variant="h4" fontWeight="bold">
                  Sign In
                </MDTypography>
              </MDBox>
              <MDTypography variant="body2" color="text">
                Enter your email and password to sign in
              </MDTypography>
            </MDBox>
            <MDBox p={3}>

              <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>

                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    fullWidth
                    name="email"
                    value={inputs.email}
                    onChange={changeHandler}
                    error={errors.emailError}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    fullWidth
                    name="password"
                    value={inputs.password}
                    onChange={changeHandler}
                    error={errors.passwordError}
                  />
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </MDBox>
                {errors.credentialsErros && (
                  <MDTypography variant="caption" color="error" fontWeight="light">
                    {errors.textError}
                  </MDTypography>
                )}

                <MDBox mt={1} display="flex" >
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                  >
                    &nbsp;&nbsp;Forgot password?
                  </MDTypography>

                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", }}
                  >
                    <a href={SUPPORT_CONTACT_URL} target="_blank" rel="noopener noreferrer" className="support-item" style={{ color: '#3992ee' }}>
                      &nbsp; Contact support
                    </a>
                  </MDTypography>


                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" size="large" fullWidth type="submit">
                    sign in
                  </MDButton>
                </MDBox>

              </MDBox>

            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout >
  );
}

export default Login;
