
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles

// Material Dashboard 2 PRO React examples
import burceMars from "assets/images/user-avatar.png";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { selectDarkMode } from "redux/materialUI/selectors";
import request from 'service/request';

function Profile() {
  const darkMode = useSelector(selectDarkMode);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });



  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await request.list({ entity: "profile" });
        if (result) {
          setName(result?.name);
          setEmail(result?.email);
          setPhone(result?.phone);
        }
        document.title = "Thunder Player | Profile";
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const saveHandler = async () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address.",
      }));
      return;
    }

    if (name === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required.",
      }));
      return;
    }

    if (phone === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Phone is required.",
      }));
      return;
    }

    const results = await request.post({ entity: "profile", jsonData: { name, email, phone } });
    if (results) {
      setErrors({
        name: "",
        email: "",
        phone: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "The email has already been taken.",
      }));
    }

  }
  const passwordHandler = async () => {

    setErrors({
      name: "",
      email: "",
      phone: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    if (currentPassword === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        currentPassword: "Current Password is required.",
      }));
      return;
    }
    if (newPassword === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: "New Password is required.",
      }));
      return;
    }
    if (newPassword.trim().length < 6) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: "New Password length should be more than 6.",
      }));
      return;
    }

    if (confirmPassword === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Confirm New Password is required.",
      }));
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "New password and confirm password do not match.",
      }));
      return;
    }

    const results = await request.post({ entity: "update-password", jsonData: { currentPassword, newPassword } });
    if (results) {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setErrors({
        name: "",
        email: "",
        phone: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        currentPassword: "Current password is incorrect.",
      }));
    }
  }

  const sidenavItems = [
    { icon: "person", label: "profile", href: "profile" },
    { icon: "receipt_long", label: "basic info", href: "basic-info" },
    { icon: "lock", label: "change password", href: "change-password" },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (

    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Card
              sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "1%",
              }}
            >
              <MDBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
              >
                {renderSidenavItems}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox my={0}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="profile">
                    <MDBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
                        </Grid>
                        <Grid item>
                          <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <MDTypography variant="h5" fontWeight="medium">
                              {name}
                            </MDTypography>
                            <MDTypography variant="button" color="text" fontWeight="medium">
                              Reseller
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox my={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="basic-info" >
                    <MDBox p={3}>
                      <MDTypography variant="h5">Base Info</MDTypography>
                    </MDBox>
                    <MDBox component="form" pb={3} px={3}>
                      <Grid container spacing={3}>
                        <Grid item md={12} lg={12} xs={12}>
                          <MDInput
                            label="Name"
                            value={name}
                            onChange={(e) => (setName(e.target.value))}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder=""
                            error={!!errors.name}
                            helperText={errors.name}
                            sx={{ '& .MuiFormHelperText-root': { color: 'red' } }}
                          />
                        </Grid>
                        <Grid item md={12} lg={12} xs={12}>
                          <MDInput
                            label="Email"
                            value={email}
                            onChange={(e) => (setEmail(e.target.value))}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder=""
                            error={!!errors.email}
                            helperText={errors.email}
                            sx={{ '& .MuiFormHelperText-root': { color: 'red' } }}
                          />
                        </Grid>
                        <Grid item md={12} lg={12} xs={12}>
                          <MDInput
                            label="Phone"
                            value={phone}
                            onChange={(e) => (setPhone(e.target.value))}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder=""
                            error={!!errors.phone}
                            helperText={errors.phone}
                            sx={{ '& .MuiFormHelperText-root': { color: 'red' } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} alignItems="center" pt={5}>
                        <MDButton fullWidth sx={{ height: 45 }} variant="gradient" color="info" onClick={saveHandler}>
                          Save
                        </MDButton> &nbsp;
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox my={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="change-password">
                    <MDBox p={3}>
                      <MDTypography variant="h5">Change Password</MDTypography>
                    </MDBox>
                    <MDBox component="form" pb={3} px={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <MDInput
                            value={currentPassword}
                            onChange={(e) => (setCurrentPassword(e.target.value))}
                            error={!!errors.currentPassword}
                            helperText={errors.currentPassword}
                            fullWidth
                            label="Current Password"
                            inputProps={{ type: "password", autoComplete: "" }}
                            sx={{ '& .MuiFormHelperText-root': { color: 'red' } }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            fullWidth
                            value={newPassword}
                            onChange={(e) => (setNewPassword(e.target.value))}
                            error={!!errors.newPassword}
                            helperText={errors.newPassword}
                            label="New Password"
                            inputProps={{ type: "password", autoComplete: "" }}
                            sx={{ '& .MuiFormHelperText-root': { color: 'red' } }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            fullWidth
                            value={confirmPassword}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            onChange={(e) => (setConfirmPassword(e.target.value))}
                            label="Confirm New Password"
                            inputProps={{ type: "password", autoComplete: "" }}
                            sx={{ '& .MuiFormHelperText-root': { color: 'red' } }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} alignItems="center" pt={5}>
                        <MDButton fullWidth sx={{ height: 45 }} variant="gradient" color="info" onClick={passwordHandler}>
                          Update password
                        </MDButton> &nbsp;
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
            {/* <Grid item pt={2} lg={3} alignItems="center">
              <MDButton fullWidth sx={{ height: 45 }} variant="gradient" color="secondary" onClick={saveHandler}>
                Save
              </MDButton> &nbsp;
            </Grid> */}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Profile;
