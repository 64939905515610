import * as actionTypes from './types';

const INITIAL_STATE = {
  miniSidenav: false,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: 'primary',
  transparentNavbar: true,
  fixedNavbar: false,
  openConfigurator: false,
  direction: 'ltr',
  layout: 'dashboard',
  darkMode: true,
};

const materialUIReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_MINI_SIDENAV:
      return { ...state, miniSidenav: action.payload };
    case actionTypes.SET_TRANSPARENT_SIDENAV:
      return { ...state, transparentSidenav: action.payload };
    case actionTypes.SET_WHITE_SIDENAV:
      return { ...state, whiteSidenav: action.payload };
    case actionTypes.SET_SIDENAV_COLOR:
      return { ...state, sidenavColor: action.payload };
    case actionTypes.SET_TRANSPARENT_NAVBAR:
      return { ...state, transparentNavbar: action.payload };
    case actionTypes.SET_FIXED_NAVBAR:
      return { ...state, fixedNavbar: action.payload };
    case actionTypes.SET_OPEN_CONFIGURATOR:
      return { ...state, openConfigurator: action.payload };
    case actionTypes.SET_DIRECTION:
      return { ...state, direction: action.payload };
    case actionTypes.SET_LAYOUT:
      return { ...state, layout: action.payload };
    case actionTypes.SET_DARKMODE:
      return { ...state, darkMode: action.payload };
    default:
      return state;
  }
};

export default materialUIReducer;
