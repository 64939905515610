import * as actionTypes from './types';

export const setMiniSidenav = (value) => ({
  type: actionTypes.SET_MINI_SIDENAV,
  payload: value,
});

export const setTransparentSidenav = (value) => ({
  type: actionTypes.SET_TRANSPARENT_SIDENAV,
  payload: value,
});

export const setWhiteSidenav = (value) => ({
  type: actionTypes.SET_WHITE_SIDENAV,
  payload: value,
});

export const setSidenavColor = (value) => ({
  type: actionTypes.SET_SIDENAV_COLOR,
  payload: value,
});

export const setTransparentNavbar = (value) => ({
  type: actionTypes.SET_TRANSPARENT_NAVBAR,
  payload: value,
});

export const setFixedNavbar = (value) => ({
  type: actionTypes.SET_FIXED_NAVBAR,
  payload: value,
});

export const setOpenConfigurator = (value) => ({
  type: actionTypes.SET_OPEN_CONFIGURATOR,
  payload: value,
});

export const setDirection = (value) => ({
  type: actionTypes.SET_DIRECTION,
  payload: value,
});

export const setLayout = (value) => ({
  type: actionTypes.SET_LAYOUT,
  payload: value,
});

export const setDarkMode = (value) => ({
  type: actionTypes.SET_DARKMODE,
  payload: value,
});
