import * as actionTypes from './types';
import * as authService from 'service/auth';

// import history from '@/utils/history';
import { showNotification } from '../notification/actions';

export const login =
  ({ loginData }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.LOADING_REQUEST,
        payload: { loading: true },
      });
      try {
        const data = await authService.login({ loginData });

        if (data && data.success === true) {
          console.log('%redux\auth\actions.js:16 data.result', 'color: #007acc;', data.result);
          window.localStorage.setItem('isLoggedIn', true);
          window.localStorage.setItem('token', JSON.parse(data.result.token));
          window.localStorage.setItem('auth', JSON.stringify(data.result.reseller));
          dispatch({
            type: actionTypes.LOGIN_SUCCESS,
            payload: data.result.reseller,
          });
          dispatch(showNotification('Login successful!', 'success'));
          // history.push('/');
          // window.location.href = '/dashboard';
        } else {
          dispatch({
            type: actionTypes.FAILED_REQUEST,
            payload: data,
          });
          dispatch(showNotification(data?.message, 'error'));
        }
      } catch (error) {

        dispatch({
          type: actionTypes.FAILED_REQUEST,
          payload: data,
        });
        dispatch(showNotification('An error occurred. Please try again.', 'error'));
      }
    };

export const logout = () => async (dispatch) => {
  authService.logout();
  dispatch({
    type: actionTypes.LOGOUT_SUCCESS,
  });
  // history.push('/login');
  dispatch(showNotification('Logout successful!', 'success'));
  // window.location.href = window.location.origin
};
