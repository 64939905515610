export const SET_MINI_SIDENAV = 'SET_MINI_SIDENAV';
export const SET_TRANSPARENT_SIDENAV = 'SET_TRANSPARENT_SIDENAV';
export const SET_WHITE_SIDENAV = 'SET_WHITE_SIDENAV';
export const SET_SIDENAV_COLOR = 'SET_SIDENAV_COLOR';
export const SET_TRANSPARENT_NAVBAR = 'SET_TRANSPARENT_NAVBAR';
export const SET_FIXED_NAVBAR = 'SET_FIXED_NAVBAR';
export const SET_OPEN_CONFIGURATOR = 'SET_OPEN_CONFIGURATOR';
export const SET_DIRECTION = 'SET_DIRECTION';
export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_DARKMODE = 'SET_DARKMODE';
