import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    TextField
} from "@mui/material";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import dialogStyle from "layouts/config/dialogStyle"
import request from 'service/request';

const TransferDialog = ({ open, handleClose, handleTransfer, darkMode, sourceDevice: initialSourceDevice }) => {
    const [sourceDevice, setSourceDevice] = useState(null);
    const [targetDevice, setTargetDevice] = useState(null);
    const [deviceCode, setDeviceCode] = useState("");
    const [devicesList, setDevicesList] = useState([]);
    const [macAddress, setMacAddress] = useState("");
    const [macAddressError, setMacAddressError] = useState(false);

    useEffect(() => {
        setSourceDevice(initialSourceDevice);
        setTargetDevice(null);
        setDeviceCode("");
        setMacAddress("");
        getTableData();
    }, [open]);

    const getTableData = async () => {
        try {
            const data = await request.list({ entity: "devices", options: { active_status: true, pageIndex: 0, pageSize: 100 } });
            setDevicesList(data.result);
        } catch (error) {
        } finally {
        }
    };
    const handleSourceDeviceChange = (event, newValue) => {
        setSourceDevice(newValue);
    };

    const handleTargetDeviceChange = (event, newValue) => {
        setTargetDevice(newValue);
    };

    const handleDeviceCodeChange = (event) => {
        setDeviceCode(event.target.value);
    };

    const handleMacAddressChange = (event) => {
        const newValue = event.target.value;
        const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        setMacAddress(newValue);
        setMacAddressError(!macAddressRegex.test(newValue));
    };
    const handleTransferLocal = () => {
        if (sourceDevice && deviceCode !== "" && macAddress !== "" && !macAddressError) {
            handleTransfer({ sourceDevice, macAddress, deviceCode });
        }
        // if (sourceDevice && targetDevice && deviceCode !== "") {
        //     handleTransfer({ sourceDevice, targetDevice, deviceCode });
        // }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="transfer-dialog-title"
            maxWidth="sm"
            fullWidth
            sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: '100%' } }}
        >
            <Box style={dialogStyle(darkMode)} p={1}>
                <DialogTitle id="transfer-dialog-title">Transfer Credits</DialogTitle>
                <Box px={2}>
                    <Box mb={2}>
                        <Autocomplete
                            options={devicesList}
                            getOptionLabel={(option) => option.mac_address}
                            value={sourceDevice}
                            onChange={handleSourceDeviceChange}
                            renderInput={(params) => <TextField {...params} label="Source Device" variant="outlined" fullWidth
                                InputLabelProps={{ shrink: true, }}
                            />}
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Device Code"
                            type="deviceCode"
                            value={deviceCode}
                            onChange={handleDeviceCodeChange}
                            fullWidth
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                            InputLabelProps={{ shrink: true, }}
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Mac Address"
                            type="macAddress"
                            value={macAddress}
                            onChange={handleMacAddressChange}
                            fullWidth
                            // InputProps={{ inputProps: { min: 0 } }}
                            InputProps={{
                                inputProps: {
                                    inputMode: "text",
                                    pattern: "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$",
                                },
                            }}
                            InputLabelProps={{ shrink: true, }}
                            variant="outlined"
                            placeholder="00:00:00:00:00:00"
                            error={macAddressError}
                            helperText={macAddressError ? 'Invalid Mac Address' : ''}
                        />
                    </Box>
                </Box>
                <DialogActions>
                    <Grid fullWidth container spacing={3} alignContent="end" justifyContent="end" mt={2}>
                        <Grid item lg={4}>
                            <MDButton fullWidth sx={{ height: 40 }} variant="gradient" color="secondary" autoFocus onClick={handleClose}>
                                Cancel
                            </MDButton>
                        </Grid>
                        <Grid item lg={4}>
                            <MDButton fullWidth sx={{ height: 40 }} variant="gradient" color="primary" autoFocus onClick={handleTransferLocal}>
                                Transfer
                            </MDButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

TransferDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleTransfer: PropTypes.func.isRequired,
    sourceDevice: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        mac_address: PropTypes.string.isRequired,
    }),
    darkMode: PropTypes.bool.isRequired,
};

export default TransferDialog;
