import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { useLocation } from "react-router-dom";
import { setLayout } from "redux/materialUI/actions";
import { selectLayout } from "redux/materialUI/selectors";

function PageLayout({ background, children }) {
  const dispatch = useDispatch();
  const layout = useSelector(selectLayout);
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setLayout("page"));
  }, [dispatch, pathname]);

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      {children}
    </MDBox>
  );
}

PageLayout.defaultProps = {
  background: "default",
};

PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
