import Dashboard from "layouts/dashboard";
import Devices from "layouts/devices";
import CreditList from "layouts/credits";
import Logout from "layouts/auth/logout";
import Profile from "layouts/profile";

import MDBox from "components/MDBox";
// @mui icons

// Images
import creditsIcon from "assets/images/credits.png";
import dashboardIcon from "assets/images/dashboard.png";
import devicesIcon from "assets/images/devices.png";
import logoutIcon from "assets/images/logout.png";
import userIcon from "assets/images/user-avatar.png";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <MDBox component="img" src={dashboardIcon} alt="Devices" width="20px" color="white" sx={{ filter: "brightness(0) invert(1)" }} />,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Devices",
    key: "devices",
    icon: <MDBox component="img" src={devicesIcon} alt="Devices" width="20px" color="white" sx={{ filter: "brightness(0) invert(1)" }} />,
    route: "/devices",
    component: <Devices />,
  },
  {
    type: "collapse",
    name: "Credits",
    key: "credits",
    icon: <MDBox component="img" src={creditsIcon} alt="Devices" width="25px" color="white" sx={{ filter: "brightness(0) invert(1)" }} />,
    route: "/credits",
    component: <CreditList />,
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <MDBox component="img" src={userIcon} alt="Devices" width="20px" color="white" sx={{ filter: "brightness(0) invert(1)" }} />,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <MDBox component="img" src={logoutIcon} alt="Devices" width="20px" color="white" sx={{ filter: "brightness(0) invert(1)" }} />,
    route: "/logout",
    component: <Logout />,
  },
];

export default routes;
