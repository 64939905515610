import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; // Import necessary Redux hooks
import PropTypes from "prop-types";
import { setMiniSidenav } from "redux/materialUI/actions"; // Import the appropriate action
import { useLocation } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const dispatch = useDispatch(); // Get the dispatch function from Redux
  const miniSidenav = useSelector((state) => state.materialUI.miniSidenav); // Get miniSidenav state from Redux
  const transparentNavbar = useSelector((state) => state.materialUI.transparentNavbar); // Get transparentNavbar state from Redux
  const fixedNavbar = useSelector((state) => state.materialUI.fixedNavbar); // Get fixedNavbar state from Redux
  const darkMode = useSelector((state) => state.materialUI.darkMode); // Get darkMode state from Redux
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // Rest of the useEffect logic remains the same...

  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => dispatch(setMiniSidenav(!miniSidenav));
  const handleCloseMenu = () => setOpenMenu(false);


  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  return(
      
    <IconButton
      size="small"
      disableRipple
      color="inherit"
      sx={navbarMobileMenu}
      onClick={handleMiniSidenav}
    >
      <Icon sx={iconsStyle} fontSize="medium">
        {miniSidenav ? "menu_open" : "menu"}
      </Icon>
    </IconButton>
  )

}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
