import * as actionTypes from './types';

const INITIAL_STATE = {
    open: false,
    message: '',
    severity: 'info', // 'success', 'error', 'warning', 'info'
};

const notificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.SHOW_NOTIFICATION:
            return {
                ...state,
                open: true,
                message: action.payload.message,
                severity: action.payload.severity,
            };
        case actionTypes.HIDE_NOTIFICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default notificationReducer;
