import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import MDButton from "components/MDButton";
import dialogStyle from "layouts/config/dialogStyle";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const ActiveDialog = ({ open, handleClose, handleSubmit, darkMode }) => {
    const [macAddress, setMacAddress] = useState("");
    const [type, setType] = useState(1);
    const [macAddressError, setMacAddressError] = useState(false);

    useEffect(() => {
        setMacAddress("");
    }, [open]);

    const handleMacAddressChange = (event) => {
        const newValue = event.target.value;
        const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        setMacAddress(newValue);
        setMacAddressError(!macAddressRegex.test(newValue));
    };
    const handleTypeChange = (event) => {
        setType(event.target.value);
    }
    const handleSubmitLocal = () => {
        if (macAddress !== "" && !macAddressError) {
            handleSubmit({ macAddress, type });
        }
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="transfer-dialog-title"
            maxWidth="sm"
            fullWidth
            sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: '100%' } }}
        >
            <Box style={dialogStyle(darkMode)} p={1}>
                <DialogTitle id="transfer-dialog-title">Active Device</DialogTitle>
                <Box px={2}>
                    <Box mb={2}>
                        <TextField
                            label="Mac Address"
                            type="macAddress"
                            value={macAddress}
                            onChange={handleMacAddressChange}
                            fullWidth
                            // InputProps={{ inputProps: { min: 0 } }}
                            InputProps={{
                                inputProps: {
                                    inputMode: "text",
                                    pattern: "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$",
                                },
                            }}
                            InputLabelProps={{ shrink: true, }}
                            variant="outlined"
                            placeholder="00:00:00:00:00:00"
                            error={macAddressError}
                            helperText={macAddressError ? 'Invalid Mac Address' : ''}
                        />
                    </Box>
                    <Box mb={2}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={type}
                                onChange={handleTypeChange}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="1" control={<Radio />} label="1 Year (1 credit)" />
                                <FormControlLabel value="2" control={<Radio />} label="Lifetime (2 credits)" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                <DialogActions>
                    <Grid fullWidth container spacing={3} alignContent="end" justifyContent="end" mt={2}>
                        <Grid item lg={4}>
                            <MDButton fullWidth sx={{ height: 40 }} variant="gradient" color="secondary" autoFocus onClick={handleClose}>
                                Cancel
                            </MDButton>
                        </Grid>
                        <Grid item lg={4}>
                            <MDButton fullWidth sx={{ height: 40 }} variant="gradient" color="primary" autoFocus onClick={handleSubmitLocal}>
                                Active
                            </MDButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

ActiveDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    darkMode: PropTypes.bool.isRequired,
};

export default ActiveDialog;
