import * as actionTypes from './types';

export const showNotification = (message, severity) => ({
    type: actionTypes.SHOW_NOTIFICATION,
    payload: { message, severity },
});

export const hideNotification = () => ({
    type: actionTypes.HIDE_NOTIFICATION,
});
