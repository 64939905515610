import React, { useEffect, useCallback } from 'react';
// import { Button, Result } from 'antd';
import { useDispatch } from 'react-redux';
import { logout as logoutAction } from 'redux/auth/actions';
import Loading from 'components/Loading';

const Logout = () => {
    const dispatch = useDispatch();
    function asyncLogout() {
        return dispatch(logoutAction());
    }
    useEffect(() => {
        asyncLogout();
    }, []);
    return <Loading />;
};
export default Logout;
