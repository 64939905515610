import { combineReducers } from 'redux';

import materialUIReducer from './materialUI/reducer';
import authReducer from './auth/reducer';
import notificationReducer from './notification/reducer';

import * as actionTypes from './auth/types';

// Combine all reducers.

const appReducer = combineReducers({
  materialUI: materialUIReducer,
  auth: authReducer,
  notification: notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
