import { useEffect, useMemo, useState, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useAsyncDebounce, usePagination, useSortBy, useTable } from "react-table";

// @mui material components
import { Autocomplete, Box, CircularProgress, Icon, Table, TableBody, TableContainer, TableRow } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Checkbox from "@mui/material/Checkbox";
import DataTableBodyCell from "examples/Tables/CheckableTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/CheckableTable/DataTableHeadCell";

function DataTable({
    entriesPerPage,
    showTotalEntries,
    tableColumn,
    tableData,
    tableCount,
    pagination,
    isSorted,
    noEndBorder,
    isLoading,
    onCheckedRecordsChange,
    setPageOption
}) {

    const [checkedItems, setCheckedItems] = useState({});
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageOptions, setPageOptions] = useState([0]);
    const [sortField, setSortField] = useState("created");
    const [sortOrder, setSortOrder] = useState("desc");

    useEffect(() => {
        let page_options = [];
        for (let i = 0; i < Math.ceil(tableCount / pageSize); i++) {
            page_options.push(i);
        }
        setPageOptions(page_options);
    }, [tableCount, pageSize]);

    useEffect(() => {
        setPageOption({
            pageIndex, pageSize, sortField, sortOrder
        });
    }, [pageIndex, pageSize, sortField, sortOrder]);
    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    const entries = entriesPerPage.entries
        ? entriesPerPage.entries.map((el) => el.toString())
        : ["5", "10", "20", "50"];


    const handleAllCheckboxChange = () => {

        if (onCheckedRecordsChange) {
            if (!isAllChecked) onCheckedRecordsChange(tableData);
            else onCheckedRecordsChange([]);
        }
        const newCheckedStatus = !isAllChecked;
        tableData.map((item) => {
            setCheckedItems((prev) => ({
                ...prev,
                [item._id]: newCheckedStatus,
            }));
        })
        setIsAllChecked(newCheckedStatus);

    };


    const columns = useMemo(() => [
        {
            Header: (
                <Checkbox
                    checked={isAllChecked}
                    onChange={handleAllCheckboxChange}
                />
            ),
            accessor: "_id",
            disableSortBy: true,
            width: "5%",
            Cell: (data) => (
                <Checkbox
                    checked={isAllChecked || checkedItems[data?.row?.original?._id] || false}
                    onChange={() => handleCheckboxChange(data?.row?.original?._id)}
                />
            ),
        },
        ...tableColumn,
    ], [tableColumn, isAllChecked, checkedItems]);

    const handleCheckboxChange = (id) => {
        let cnt = 0;
        tableData.map((item) => {
            if ((checkedItems[item._id] && item._id !== id) || (!checkedItems[item._id] && item._id === id)) {
                cnt++;
            }
        })
        setIsAllChecked(tableCount === cnt ? true : false);
        // setCheckedItems((prev) => ({
        //     ...prev,
        //     [id]: !prev[id],
        // }));

        const newCheckedItems = { ...checkedItems };
        newCheckedItems[id] = !newCheckedItems[id];
        setCheckedItems(newCheckedItems);
        const checkedRecords = tableData.filter((item) => newCheckedItems[item._id]);
        if (onCheckedRecordsChange)
            onCheckedRecordsChange(checkedRecords);

    };
    // const columns = useMemo(() => tableColumn, [tableColumn]);
    const data = useMemo(() => tableData, [tableData]);

    const tableInstance = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: pageSize } },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
    } = tableInstance;

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

    // Set the entries per page value based on the select value
    const setEntriesPerPage = (value) => {
        setPageSize(value);
        setPageIndex(0);
    }

    // Render the paginations
    const renderPagination = pageOptions.map((option) => (
        <MDPagination
            item
            key={option}
            onClick={() => setPageIndex(Number(option))}
            active={pageIndex === option}
        >
            {option + 1}
        </MDPagination>
    ));

    let sortColumn = "";
    const setSortedValue = useCallback((column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
            if (sortColumn !== column.id) {
                sortColumn = column.id;

                setSortField(column.id);
                setSortOrder(column.isSortedDesc ? "desc" : "asc");
            }
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return isSorted ? "none" : false;
    }, [isSorted]);

    return (
        isLoading ?

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60vh',
                }}
            >
                <CircularProgress />
            </Box>
            :
            <TableContainer sx={{ boxShadow: "none", paddingX: "5px" }}>
                <Table {...getTableProps()}>
                    <MDBox component="thead">
                        {headerGroups.map((headerGroup, key) => (
                            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, idx) => (
                                    <DataTableHeadCell
                                        key={idx}
                                        {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                        width={column.width ? column.width : "auto"}
                                        align={column.align ? column.align : "left"}
                                        sorted={column.disableSortBy ? false : setSortedValue(column)}
                                    >
                                        {column.render("Header")}
                                    </DataTableHeadCell>
                                ))}
                            </TableRow>
                        ))}
                    </MDBox>
                    <TableBody {...getTableBodyProps()}>
                        {rows.map((row, key) => {
                            prepareRow(row);
                            return (
                                <TableRow key={key} {...row.getRowProps()}>
                                    {row.cells.map((cell, idx) => (
                                        <DataTableBodyCell
                                            key={idx}
                                            noBorder={noEndBorder && tableCount - 1 === key}
                                            align={cell.column.align ? cell.column.align : "left"}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </DataTableBodyCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>

                <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
                >
                    {showTotalEntries && (
                        <MDBox mb={{ xs: 3, sm: 0 }}>
                            <MDTypography variant="button" color="secondary" fontWeight="regular">
                                Showing {pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1} to {pageIndex === 0 ? Math.min(pageSize, tableCount) : (pageIndex === pageOptions.length - 1) ? tableCount : pageSize * (pageIndex + 1)} of {tableCount} entries
                            </MDTypography>
                        </MDBox>
                    )}
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexGrow={1}
                    >
                        {pageOptions.length > 1 && (
                            <MDPagination
                                variant={pagination.variant ? pagination.variant : "gradient"}
                                color={pagination.color ? pagination.color : "info"}
                            >
                                {pageIndex > 0 && (
                                    <>
                                        <MDPagination item onClick={() => setPageIndex(0)}>
                                            <Icon sx={{ fontWeight: "bold" }}>first_page</Icon>
                                        </MDPagination>
                                        <MDPagination item onClick={() => setPageIndex(pageIndex - 1)}>
                                            <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                                        </MDPagination>
                                    </>
                                )}
                                {
                                    renderPagination.length > 10 ?
                                        <>

                                            {pageIndex < 4 && (<>
                                                {renderPagination.filter((obj, index) => (index < 5))}
                                                ...
                                                {renderPagination.filter((obj, index) => (index > renderPagination.length - 2))}
                                            </>)}
                                            {pageIndex > renderPagination.length - 5 && (<>
                                                {renderPagination.filter((obj, index) => (index < 1))}
                                                ...
                                                {renderPagination.filter((obj, index) => (index > renderPagination.length - 6))}
                                            </>)}
                                            {pageIndex >= 4 && pageIndex <= renderPagination.length - 5 && (<>
                                                {renderPagination.filter((obj, index) => (index < 1))}
                                                ...
                                                {renderPagination.filter((obj, index) => (index > pageIndex - 2 && index < pageIndex + 2))}
                                                ...
                                                {renderPagination.filter((obj, index) => (index > renderPagination.length - 2))}
                                            </>)}
                                        </> :
                                        <>
                                            {renderPagination}
                                        </>

                                }
                                {pageIndex < pageOptions.length - 1 && (
                                    <>
                                        <MDPagination item onClick={() => setPageIndex(pageIndex + 1)}>
                                            <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                                        </MDPagination>
                                        <MDPagination item onClick={() => setPageIndex(pageOptions.length - 1)}>
                                            <Icon sx={{ fontWeight: "bold" }}>last_page</Icon>
                                        </MDPagination>
                                    </>
                                )}
                            </MDPagination>
                        )}

                    </MDBox>
                    {entriesPerPage && (
                        <MDBox display="flex" alignItems="center">
                            <MDTypography variant="caption" color="secondary" p={1}>
                                &nbsp;&nbsp;Entries per page
                            </MDTypography>
                            <Autocomplete
                                disableClearable
                                value={pageSize.toString()}
                                options={entries}
                                onChange={(event, newValue) => {
                                    setEntriesPerPage(parseInt(newValue, 10));
                                }}
                                size="small"
                                sx={{ width: "5rem" }}
                                renderInput={(params) => <MDInput {...params} />}
                            />
                        </MDBox>
                    )}
                </MDBox>
            </TableContainer>
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
    isLoading: true,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    showTotalEntries: PropTypes.bool,
    tableColumn: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired,
    tableCount: PropTypes.number.isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
    isLoading: PropTypes.bool,
    onCheckedRecordsChange: PropTypes.func,
    setPageOption: PropTypes.func
};

export default DataTable;
