import { useEffect, Suspense, useState } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';

// @mui material components
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";
import { GlobalStyles } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Loading from 'components/Loading';

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// Material Dashboard 2 React routes
import routes from "routes";
import Login from "layouts/auth/login";
import Puchase from "layouts/purchase";
import CreditList from "layouts/credits";
import VerifyEmail from "layouts/auth/verify-email";


import { useDispatch, useSelector } from 'react-redux';
import { setDarkMode, setMiniSidenav } from 'redux/materialUI/actions';
import { selectAuth } from 'redux/auth/selectors';

export default function App() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(selectAuth);
  const { miniSidenav, sidenavColor, darkMode } = useSelector(state => state.materialUI);

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch(setMiniSidenav(false));
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch(setMiniSidenav(true));
      setOnMouseEnter(false);
    }
  };

  const handleDarkMode = () => {
    // setWhiteSidenav(dispatch, darkMode);
    dispatch(setDarkMode(!darkMode));
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = allRoutes =>
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor={darkMode ? "dark" : "white"}
      color={darkMode ? "white" : "dark"}
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      sx={{ cursor: "pointer" }}
      onClick={handleDarkMode}
    >
      <Icon fontSize="small" color="inherit">
        {darkMode ? <Brightness5Icon /> : <Brightness6Icon />}
      </Icon>
    </MDBox>
  );

  const globalStyles = {
    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#5D5D5F',
      borderRadius: '6px',
      border: '3px solid #fff',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
  };
  const globalDarkStyles = {
    '::-webkit-scrollbar': {
      width: '12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#344767',
      borderRadius: '6px',
      border: '3px solid #1a2035',
    },
    '::-webkit-scrollbar-track': {
      background: '#1a2035',
    },
  };
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <GlobalStyles styles={darkMode ? globalDarkStyles : globalStyles} />
      {isLoggedIn && (
        <>
          <Sidenav
            color={sidenavColor}
            brandName="Thunder Player"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {configsButton}
        </>
      )}
      <Suspense fallback={<Loading />}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Routes>
            {
              isLoggedIn ? <>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/dashboard" />} />
                <Route path="/purchase" element={<Puchase />} />
                <Route path="/payment-success" element={<CreditList pay_type="1" />} />
                <Route path="/payment-cancel" element={<CreditList pay_type="2" />} />
              </> :
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                  <Route path="/verify-email/:token" element={<VerifyEmail />} />
                </>
            }
          </Routes>
        </AnimatePresence>
      </Suspense>
    </ThemeProvider>
  );
}
