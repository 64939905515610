import { Icon } from "@mui/material";
import creditsIcon from "assets/images/credits.png";
import MDBox from "components/MDBox";
import MDButtonRoot from "components/MDButton/MDButtonRoot";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { selectDarkMode } from "redux/materialUI/selectors";

const MDButton = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, icon, ...rest }, ref) => {
    const darkMode = useSelector(selectDarkMode);

    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
        startIcon={icon !== "" ? icon === "credits" ? <MDBox component="img" src={creditsIcon} alt="Devices" width="23px" color="white" sx={{ filter: "brightness(0) invert(1)" }} /> : <Icon fontSize="default">{icon}</Icon> : <></>}
      >
        {children}
      </MDButtonRoot>
    );
  }
);

MDButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  icon: "",
  circular: false,
  iconOnly: false,
};

MDButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string
};

export default MDButton;
